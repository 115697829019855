.content-wrapper {
  background: #f7f8f9;
  padding: 2.375rem 2.375rem;
  width: 100%;
}

.card-people {
  position: relative;
  padding-top: 20px;
}

.card-people img {
  border-radius: 20px;
  width: 100%;
}

.card-people .weather-info {
  position: absolute;
  top: 30px;
  right: 24px;
}

.card-people .weather-info sup {
  font-size: 18px;
}

.card-title {
  color: #010101;
  margin-bottom: 1.2rem;
  text-transform: capitalize;
  font-size: 1.125rem;
  font-weight: 600;
}

.bold {
  font-weight: 600;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  line-height: 1.3rem;
}

.thdiv {
  margin-top: 10px;
  margin-right: 40px;
}

.value {
  font-size: 30px;
  font-weight: 600;
  color: #4b49ac !important;
}

.card {
  border-radius: 20px;
  border: none;
  box-shadow: 0px 0px 35px 0px rgb(154 161 171 / 15%);
}

.data-icon-card-primary {
  background-color: #4b49ac;
  color: #ffffff;
}

.data-icon-card-primarys {
  background-color: #7da0fa;
  color: #ffffff;
}
.project-section {
  background-color: #fff;
  box-shadow: 4px 4px 11px 2px #e9e9e9;
  border-radius: 7px;
  margin-bottom: 26px;
}
.project-section tr td {
  color: #5a5a5a;
}
.summary-section {
  background-color: #fff;
  box-shadow: 4px 4px 11px 2px #e9e9e9;
  border-radius: 7px;
}
.summary-section tr td {
  color: #5a5a5a;
}
.vendor-chart {
  background-color: #fff;
  box-shadow: 4px 4px 11px 2px #e9e9e9;
  border-radius: 7px;
}

.wrapper {
  /* width: 300px; */
  /* height: 345px; */
  margin: auto;
}
.left-card {
  height: 475px;
  width: 200px;
}
.vendor-chart1 {
  background-color: #fff;
  box-shadow: 4px 4px 11px 2px #e9e9e9;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.right-side-div {
  background: #ffffff;
  padding: 28px 28px;
  /* margin-bottom: 23px; */
  border-radius: 6px;
  box-shadow: 6px 6px 20px 1px #e9e9e9;
}
.right-side-div h3 {
  color: #1855a7;
  font-size: 55px;
}
.right-side-div h6 {
  color: #019688;
}
.right-side-div .card-body.top-card {
  box-shadow: none;
}
.right-side-div .card.card-tale {
  box-shadow: none;
}
.img-Vendor {
  width: 50%;
}
.scroll-t{
  height: 197px;
  overflow-y: scroll;
}
.textCenter{
  width: 100%;
  margin: 30% 0 0 100%;
}
